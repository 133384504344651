





















































































































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';

import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { max } from 'vee-validate/dist/rules';

import { constants } from '@/utils/constants';
import { toasts } from '@/utils/toasts';

import {
  getProductDetails,
  getAvailableCurrencies,
  editProductDetails,
  getAvailableMeasurementUnits,
  getAvailableProductClassifications,
  getAvailableAccounts,
  addProduct,
  deleteProduct,
} from './product-catalog-api';

extend('max', max);

@Component({
  components: { ValidationObserver, ValidationProvider },
})
export default class extends Vue {
  productId: any = null;
  productCode: any = null;

  vatPercentage: any = { label: '19 %', key: 0.19 };
  productPrice: number = 0;
  currencyCode: any = { key: 'RON', label: 'RON', description: 'Lei românești' };
  productMU: any = null;
  productType: any = { type: false, label: 'Produs' };
  xcontaTrackingAccount: any = null;
  isActive: boolean = true;
  productName: string|null = null;
  isEditType: boolean = false;
  autoGenerateCode: boolean | null = false;

  modalStates = constants.productCatalogModalState;
  modalState = constants.productCatalogModalState.view;

  currencyCodeOptions: any = [];
  measurementUnitOptions: any = [];
  productTypeOptions: any[] = [
    { type: false, label: 'Produs' },
    { type: true, label: 'Serviciu' },
  ];
  statusProdusOptions: Array<any> = [
    { value: true, text: 'Activ' },
    { value: false, text: 'Inactiv' },
  ];

  vatPercentageOptions = constants.vatPercentageOptions;

  xcontaTrackingAccountOptions: Array<any> = [];

  totalClassifications: Array<any> = [];
  serviceClassifications: Array<any> = [];
  productClassifications: Array<any> = [];
  classification: any = null;

  get productClassificationOptions() {
    if (this.productType.type) {
      return this.serviceClassifications;
    }
    return this.productClassifications;
  }

  get okButtonName() {
    if (this.modalState === this.modalStates.view) {
      return 'Editează';
    }
    return 'Salvează';
  }

  get modalTitle() {
    switch (this.modalState) {
      case this.modalStates.view:
        return 'Detalii produs';
      case this.modalStates.edit:
        return 'Editare produs';
      case this.modalStates.add || this.modalStates.addExternal:
        return 'Adăugare produs';
      default:
        return 'Detalii produs';
    }
  }

  onProductTypeChange() {
    this.classification = null;
  }

  startEdit() {
    this.modalState = this.modalStates.edit;
  }

  validateWrapper(validator, value) {
    validator(value);
  }

  created() {
    this.initialize();
  }

  async initialize() {
    await this.loadProductClassifications();
  }

  async loadProductClassifications() {
    try {
      this.totalClassifications = (await getAvailableProductClassifications())?.items;
      this.productClassifications = this.totalClassifications
        .filter((e) => e.tip === constants.productClassTypes.product)
        .map((e) => ({ key: e.clasificareProdusId, label: e.denumire, classification: e }));
      this.serviceClassifications = this.totalClassifications
        .filter((e) => e.tip === constants.productClassTypes.service)
        .map((e) => ({ key: e.clasificareProdusId, label: e.denumire, classification: e }));
    } catch (err) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
    }
  }

  async confirmEdit(event) {
    const errors: Array<string> = this.validateProductDetails();

    if (errors.length) {
      this.showErrorsToast(errors, 'productCatalogUpdateError');
      return;
    }

    await editProductDetails({
      productId: this.productId,
      productCode: this.productCode,
      accountNumber: this.xcontaTrackingAccount?.key,
      clasificareProdusId: this.classification?.key,
      name: this.productName,
      measurementUnit: this.productMU.key,
      isActive: this.isActive,
      isService: this.productType.type,
      price: this.productPrice,
      vatPercentage: this.vatPercentage.key,
      currencyCode: this.currencyCode.key,
    });
    event();
    this.$emit('ok', {});
  }

  async deleteProduct(ok) {
    try {
      if (await this.$bvModal.msgBoxConfirm('Doriți să ștergeți acest produs?', {
        title: 'Atenție',
        okTitle: 'Da',
        cancelTitle: 'Nu',
        centered: true,
      })) {
        await deleteProduct(this.productId);
        ok();
        this.$emit('ok', {});
      }
    } catch (err) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
    }
  }

  validateProductDetails() {
    const errors: Array<string> = [];
    if (!this.productCode) {
      errors.push('Cod necompletat');
    }
    if (!this.classification) {
      errors.push('Clasificare necompletată');
    }
    if (this.productCode && (this.productCode.length > 20)) {
      errors.push('Codul nu poate avea mai mult de 20 de caractere');
    }
    if (!this.productName) {
      errors.push('Denumire necompletată');
    }
    if (this.productName && (this.productName.length > 150)) {
      errors.push('Denumirea nu poate avea mai mult de 150 de caractere');
    }
    if (!this.xcontaTrackingAccount) {
      errors.push('Cont evidență necompletat');
    }
    if (!this.productMU) {
      errors.push('U.M. necompletată');
    }
    if (this.productPrice === null) {
      errors.push('Pret necompletat');
    }
    return errors;
  }

  async addProduct(event) {
    const errors: Array<string> = this.validateProductDetails();

    if (errors.length) {
      this.showErrorsToast(errors, 'grnDraftSaveError');
      return;
    }

    try {
      const product = await addProduct({
        productCode: this.productCode,
        accountNumber: this.xcontaTrackingAccount?.key,
        clasificareProdusId: this.classification?.key,
        name: this.productName,
        measurementUnit: this.productMU.key,
        isActive: this.isActive,
        isService: this.productType.type,
        price: this.productPrice,
        vatPercentage: this.vatPercentage.key,
        currencyCode: this.currencyCode.key,
        autoGenerateCode: this.autoGenerateCode,
      });
      this.$emit('ok', { product });
      event();
    } catch (err) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
    }
  }

  async onOk(event) {
    if (this.modalState === this.modalStates.view) {
      this.startEdit();
    } else if (this.modalState === this.modalStates.edit) {
      await this.confirmEdit(event);
    } else if (this.modalState === this.modalStates.add
              || this.modalState === this.modalStates.addExternal) {
      await this.addProduct(event);
    }
  }

  showErrorsToast(errors, toastId: string|undefined) {
    const h = this.$createElement;
    const vNodesMsg = [
      h('p', {}, 'Trebuie corectate următorele erori:'),
      h(
        'ul',
        {},
        errors.map((e) => h('li', {}, e)),
      )];
    toasts.error(vNodesMsg, {
      title: 'Eroare de validare',
      id: toastId,
    });
  }

  getVATPercentageOption(percentage) {
    return this.vatPercentageOptions.find((option) => option.key === percentage);
  }

  getProductTypeOption(type) {
    return this.productTypeOptions.find((option) => option.type === type);
  }

  getProductMUOption(mu) {
    return this.measurementUnitOptions.find((option) => option.key === mu);
  }

  getCurrencyOption(currency) {
    return this.currencyCodeOptions.find((option) => option.key === currency);
  }

  getXcontaTrackingAccountOption(account) {
    return this.xcontaTrackingAccountOptions.find((x) => x.key === account)
      || {
        key: account,
        label: account,
      };
  }

  loadDataIntoModel(result) {
    this.productId = result.productId;
    this.productCode = result.productCode;
    this.productName = result.name;
    this.productMU = this.getProductMUOption(result.measurementUnit);
    this.isActive = result.isActive;
    this.productType = this.getProductTypeOption(result.isService);
    this.productPrice = result.price;
    this.vatPercentage = this.getVATPercentageOption(result.vatPercentage);
    this.xcontaTrackingAccount = this.getXcontaTrackingAccountOption(result.accountNumber);
    this.classification = this.totalClassifications
      .find((e) => e.clasificareProdusId === result.clasificareProdusId);
    this.classification = {
      key: this.classification?.clasificareProdusId,
      label: this.classification?.denumire,
      classification: this.classification,
    };
    this.currencyCode = this.getCurrencyOption(result.currencyCode);
  }

  loadEmptyModel() {
    this.productId = null;
    this.productCode = null;
    this.vatPercentage = { label: '19 %', key: 0.19 };
    this.productPrice = 0;
    this.currencyCode = { key: 'RON', label: 'RON', description: 'Lei românești' };
    this.productMU = null;
    this.productType = { type: false, label: 'Produs' };
    this.xcontaTrackingAccount = null;
    this.classification = null;
    this.isActive = true;
    this.productName = null;
    this.isEditType = false;
  }

  loadExternalAddModel(productCode, autoGenerateCode) {
    this.productTypeOptions = [
      { type: false, label: 'Produs' },
    ];

    this.productId = null;
    this.autoGenerateCode = autoGenerateCode;
    this.productCode = productCode;
    this.vatPercentage = { label: '19 %', key: 0.19 };
    this.productPrice = 0;
    this.currencyCode = { key: 'RON', label: 'RON', description: 'Lei românești' };
    this.productMU = null;
    this.productType = { type: false, label: 'Produs' };
    this.xcontaTrackingAccount = null;
    this.classification = null;
    this.isActive = true;
    this.productName = null;
    this.isEditType = false;
  }

  async showModal(productCode, modalState, autoGenerateCode) {
    try {
      this.modalState = modalState;
      const result = await getProductDetails(productCode);
      this.currencyCodeOptions = (await getAvailableCurrencies()).map((currency) => ({
        key: currency.currencyCode,
        label: currency.currencyCode,
        description: currency.name,
      }));
      this.measurementUnitOptions = (await getAvailableMeasurementUnits()).map((mu) => ({
        key: mu.measurementUnitCode,
        label: mu.name,
      }));
      this.xcontaTrackingAccountOptions = (await getAvailableAccounts()).map((acc) => ({
        ...acc,
        key: acc.numar,
        label: `${acc.numar} - ${acc.denumire}`,
      }));

      if (this.modalState === this.modalStates.view) {
        this.loadDataIntoModel(result);
      } else if (this.modalState === this.modalStates.add) {
        this.loadEmptyModel();
      } else if (this.modalState === this.modalStates.addExternal) {
        this.loadExternalAddModel(productCode, autoGenerateCode);
      }
    } catch (err) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
    }
    (<any>(this.$refs.modal)).show();
  }

  onProductClassificationClose(validator, classification) {
    this.validateWrapper(validator, classification);

    if (classification?.classification) {
      if (!this.productType || !this.productType.type) {
        this.xcontaTrackingAccount = this.xcontaTrackingAccountOptions.find((x) => x.key === classification.classification.contGestiune)
          || this.xcontaTrackingAccount;
      } else {
        this.xcontaTrackingAccount = this.xcontaTrackingAccountOptions.find((x) => x.key === classification.classification.contCheltuiala)
          || this.xcontaTrackingAccountOptions.find((x) => x.key === classification.classification.contVenit)
          || this.xcontaTrackingAccount;
      }
    }
  }
}

