import httpClient from '@/services/http-client';

export async function getAvailableProducts(name, productType, status, currentGridPage, itemsPerPage, inventoryOrderCriteria) {
  const response = await httpClient.get('/api/adm/product-catalog/list', {
    params: {
      lookupTerm: name,
      isService: productType,
      productStatus: status,
      pageNumber: currentGridPage,
      perPage: itemsPerPage,
      inventoryOrderCriteria,
    },
  });

  return response.data;
}

export async function getProductDetails(productCode) {
  const response = await httpClient.get('/api/adm/product-catalog/details', {
    params: {
      productCode,
    },
  });
  return response.data;
}

export async function editProductDetails({
  productId,
  productCode,
  accountNumber,
  clasificareProdusId,
  name,
  measurementUnit,
  isActive,
  isService,
  price,
  vatPercentage,
  currencyCode,
}) {
  const response = await httpClient.post(`/api/adm/product-catalog/item/${productId}`, {
    productCode,
    accountNumber,
    clasificareProdusId,
    name,
    measurementUnit,
    isActive,
    isService,
    price,
    vatPercentage,
    currencyCode,
  });
  return response.data;
}

export async function addProduct({
  productCode,
  accountNumber,
  clasificareProdusId,
  name,
  measurementUnit,
  isActive,
  isService,
  price,
  vatPercentage,
  currencyCode,
  autoGenerateCode,
}) {
  const response = await httpClient.post('/api/adm/product-catalog/item', {
    productCode,
    accountNumber,
    clasificareProdusId,
    name,
    measurementUnit,
    isActive,
    isService,
    price,
    vatPercentage,
    currencyCode,
    autoGenerateCode,
  });
  return response.data;
}

export async function deleteProduct(productCode) {
  const response = await httpClient.delete(`/api/adm/product-catalog/item/${productCode}`);
  return response.data;
}

export async function getAvailableCurrencies() {
  const response = await httpClient.get('/api/adm/product-catalog/available-currencies');
  return response.data;
}

export async function getAvailableMeasurementUnits() {
  const response = await httpClient.get('/api/adm/product-catalog/available-mu');
  return response.data;
}

export async function getAvailableProductClassifications() {
  const response = await httpClient.get('/api/adm/product-classification/list');
  return response.data;
}

export async function getAvailableAccounts() {
  const response = await httpClient.get('/api/adm/product-classification/accounts');
  return response.data;
}
